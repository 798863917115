<label class="control-label" *ngIf="label">
  {{ label }}
  <span *ngIf="requiredQuestionFlag" style="color: red; font-size: large">*</span>
</label>

<ng-container [ngSwitch]="type">
  <input [type]="type || 'text'" class="form-control" *ngSwitchDefault autocomplete="off" [ngClass]="{
      'is-invalid':
        eventoFormControl.status === 'INVALID' && eventoFormControl.touched,
      'is-valid':
        eventoFormControl.status === 'VALID' &&
        eventoFormControl.touched &&
        eventoFormControl.hasValidator()
    }" [readonly]="readonly" [formControl]="formControl" [placeholder]="placeholder" />

  <ng-container *ngSwitchCase="'number'">
    <input type="number" class="form-control" autocomplete="off" [ngClass]="{
        'is-invalid':
          eventoFormControl.status === 'INVALID' && eventoFormControl.touched,
        'is-valid':
          eventoFormControl.status === 'VALID' &&
          eventoFormControl.touched &&
          eventoFormControl.hasValidator()
      }" [readonly]="readonly" [formControl]="formControl" [placeholder]="placeholder" [min]="min" [max]="max" />
  </ng-container>

  <ng-container *ngSwitchCase="'datepicker'">
    <div class="datepicker-field">
      <input class="form-control" type="text" [ngClass]="{
          'is-invalid':
            eventoFormControl.status === 'INVALID' && eventoFormControl.touched,
          'is-valid':
            eventoFormControl.status === 'VALID' &&
            eventoFormControl.touched &&
            eventoFormControl.hasValidator()
        }" mwlFlatpickr [formControl]="formControl" [altInput]="true" [minDate]="minDate" [maxDate]="maxDate"
        [nextArrow]="nextArrow" [prevArrow]="prevArrow" [convertModelValue]="true" [enableTime]="false"
        [monthSelectorType]="'dropdown'" dateFormat="d/m/Y" altFormat="d/m/Y" [placeholder]="placeholder" />
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'datetimepicker'">
    <div class="datepicker-field">
      <input class="form-control" type="text" mwlFlatpickr [formControl]="formControl" [altInput]="true"
        [nextArrow]="nextArrow" [prevArrow]="prevArrow" [convertModelValue]="true" [enableTime]="true"
        [minDate]="minDate" [maxDate]="maxDate" [monthSelectorType]="'dropdown'" dateFormat="d/m/Y H:i"
        altFormat="d/m/Y H:i" [placeholder]="placeholder" />
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'mobile'">
    <ngx-intl-tel-input [cssClass]="'form-control'" [ngClass]="{
        'is-invalid':
          eventoFormControl.status === 'INVALID' && eventoFormControl.touched
      }" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
      customPlaceholder="05XXXXXXXX" [searchCountryFlag]="false"
      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
      [selectedCountryISO]="country ? country : CountryISO.SaudiArabia" [maxLength]="15" [separateDialCode]="false"
      [phoneValidation]="true" [numberFormat]="PhoneNumberFormat.International"
      [formControl]="formControl"></ngx-intl-tel-input>
  </ng-container>

  <textarea class="form-control" autocomplete="off" *ngSwitchCase="'textarea'" [ngClass]="{
      'is-invalid':
        eventoFormControl.status === 'INVALID' && eventoFormControl.touched,
      'is-valid':
        eventoFormControl.status === 'VALID' &&
        eventoFormControl.touched &&
        eventoFormControl.hasValidator()
    }" [formControl]="formControl" [placeholder]="placeholder"></textarea>
</ng-container>

<i [class]="icon" *ngIf="icon" parent-class="is-havingIcon" (click)="searchByIcon ? onSearchIconClick() : ''"
  [style.cursor]="searchByIcon ? 'pointer' : 'default'"></i>

<img *ngIf="iconCustom && type != 'password' && !viewPassword" [src]="iconCustom" class="is-havingIcon custom-icon" />

<i *ngIf="iconCustom && type == 'password' && !viewPassword" [class]="iconCustom" style="color: #555770"
  (click)="viewPassword = true; type = 'text'" parent-class="is-havingPasswordIcon"></i>
<i *ngIf="iconCustom && (type == 'password' || type == 'text') && viewPassword" class="far fa-eye form-icon"
  style="color: #555770" (click)="viewPassword = false; type = 'password'" parent-class="is-havingPasswordIcon"></i>

<app-error-input [message]="requiredMessage" *ngIf="
    requiredMessage &&
    eventoFormControl.hasError('required') &&
    eventoFormControl.touched
  ">
</app-error-input>
<app-error-input [message]="mustMatchMessage" *ngIf="
    mustMatchMessage &&
    eventoFormControl.hasError('mustMatch') &&
    eventoFormControl.touched
  ">
</app-error-input>

<app-error-input [message]="minlengthMessage" *ngIf="
    minlengthMessage &&
    eventoFormControl.hasError('minlength') &&
    eventoFormControl.touched
  ">
</app-error-input>
<app-error-input [message]="maxlengthMessage" *ngIf="
    maxlengthMessage &&
    eventoFormControl.hasError('maxlength') &&
    eventoFormControl.touched
  ">
</app-error-input>
<app-error-input [message]="patternMessage" *ngIf="
    patternMessage &&
    eventoFormControl.hasError('pattern') &&
    eventoFormControl.touched
  ">
</app-error-input>
<app-error-input [message]="validatePhoneNumberMessage" *ngIf="
    validatePhoneNumberMessage &&
    eventoFormControl.hasError('validatePhoneNumber') &&
    eventoFormControl.touched
  ">
</app-error-input>
<app-error-input [message]="minMessage" *ngIf="
    minMessage && eventoFormControl.hasError('min') && eventoFormControl.touched
  ">
</app-error-input>
<app-error-input [message]="maxMessage" *ngIf="
    maxMessage && eventoFormControl.hasError('max') && eventoFormControl.touched
  ">
</app-error-input>
<app-error-input [message]="minDateMessage" *ngIf="minDateMessage && eventoFormControl.hasError('minDate')">
</app-error-input>
<app-error-input [message]="maxDateMessage" *ngIf="maxDateMessage && eventoFormControl.hasError('maxDate')">
</app-error-input>
<app-error-input [message]="emailMessage" *ngIf="
    emailMessage &&
    eventoFormControl.hasError('email') &&
    eventoFormControl.touched
  ">
</app-error-input>

<app-error-input [message]="greaterThanMinMessage" *ngIf="
    greaterThanMinMessage &&
    eventoFormControl.hasError('greaterThanMin') &&
    eventoFormControl.touched
  ">
</app-error-input>

<app-error-input [message]="onlyWhitespaceMessage" *ngIf="
    onlyWhitespaceMessage &&
    eventoFormControl.hasError('onlyWhitespace') &&
    eventoFormControl.touched
  ">
</app-error-input>