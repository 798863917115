import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Validators } from 'ngx-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom, tap } from 'rxjs';
import { EventService } from 'src/app/dashboard/new-event/event.service';
import { eventsApi } from '../../constants/api.constants';
import { ISchedule } from '../../models/ISchedule.model';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-add-dates-modal',
  templateUrl: './add-dates-modal.component.html',
})
export class AddDatesModalComponent implements OnInit {
  @Input() eventId: string;
  @Input() eventSchedules: ISchedule[];
  addDatesForm: UntypedFormGroup;
  eventStart: Date;
  eventEnd: Date;
  today: Date;
  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private eventService: EventService,
    private spinner: NgxSpinnerService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.today = new Date();
    this.eventStart = new Date(this.eventSchedules[0].startAt * 1000);
    this.eventEnd = new Date(
      this.eventSchedules[this.eventSchedules.length - 1].endAt * 1000
    );
    this.cd.detectChanges();
  }

  private initForm(): void {
    this.addDatesForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  addDates(): void {
    this.spinner.show();
    const { startDate, endDate } = this.addDatesForm.value;
    const body = {
      start: new Date(startDate).getTime() / 1000,
      end: new Date(endDate).getTime() / 1000,
    };

    if (this.eventId) {
      firstValueFrom(
        this.dataService
          .post(`${eventsApi}/${this.eventId}/schedules/range`, body)
          .pipe(
            tap((res) => {
              this.spinner.hide();
              this.eventService.selectedEventUpdated.next(true);
              this.activeModal.close(res);
            })
          )
      );
    } else {
      this.spinner.hide();
      this.activeModal.close(body);
    }
  }
}
