import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-evento-image-multiple-uploader',
  templateUrl: './evento-image-multiple-uploader.component.html',
  styleUrls: ['./evento-image-multiple-uploader.component.scss'],
})
export class EventoImageMultipleUploaderComponent {
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  rawFileArray = [];
  base64ImgArray = [];
  @Output() imagesSelected = new EventEmitter<any[]>();
  @Input() ticketPhotos: any[] = [];

  constructor() {}

  onImagesPicked(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        this.convertToBase64(files[i]);
      }
    }
  }

  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      const base64Image = { url: reader.result as string, isNew: true };
      this.base64ImgArray.push(base64Image);
      this.emitImages();
    };
    reader.onerror = (error) => {
      this.imageError = 'Failed to load the image';
    };
    reader.readAsDataURL(file);
  }

  removeImage(index: number, isExisting: boolean = false): void {
    if (isExisting) {
      this.ticketPhotos.splice(index, 1);
    } else {
      this.base64ImgArray.splice(index, 1);
    }
    this.emitImages();
  }

  emitImages() {
    const allImages = this.ticketPhotos
      ? [...this.ticketPhotos, ...this.base64ImgArray]
      : [...this.base64ImgArray];

    this.imagesSelected.emit(allImages);
  }
}
