<div class="noData">
  <div class="noData-cover">
    <img src="{{ img }}" alt="" />
  </div>
  <h2 class="noData-title" translate>{{ title }}</h2>
  <p class="noData-desc" *ngIf="desc" translate>{{ desc }}</p>
  <a [routerLink]="link.path" *ngIf="link" translate class="btn noData-actionBtn">
    {{ link.label }}
  </a>

  <ng-content></ng-content>
</div>
