<div class="horizontalTicketCard">
  <div class="horizontalTicketCard-qrcodDetails">
    <div class="qrcodFrame">
      <img
        src="https://media.istockphoto.com/vectors/code-illustration-vector-id828088276?k=20&m=828088276&s=612x612&w=0&h=JnX2DFXVGyTe-wDXdXhTG9p7yPmbd-jO3qWRfCQnHgE="
        alt="" class="image">
    </div>
    <div class="horizontalTicketCard-personDetails">
      <h4 class="horizontalTicketCard-name">محمد ابو سليمان</h4>
      <p class="horizontalTicketCard-phone">966502729394</p>
      <p class="horizontalTicketCard-email">s@s.sa</p>
    </div>
  </div>

  <div class="horizontalTicketCard-seperator">
    <div class="horizontalTicketCard-dashedLine"></div>
  </div>

  <div class="horizontalTicketCard-details">
    <h3 class="horizontalTicketCard-type">تذكره ذهبيه</h3>
    <p class="horizontalTicketCard-price">٣٠ ريال</p>
    <button type="button" class="btn horizontalTicketCard-downloadBtn">
      <i class="fas fa-cloud-download-alt mr-2"></i>
      تحميل الفاتوره
    </button>
  </div>
</div>
<!-- ============================== --> 