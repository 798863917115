import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { BehaviorSubject, firstValueFrom, Observable, tap } from 'rxjs';
import { tokenApi, userProfileApi } from '../shared/constants/api.constants';
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  ROLES,
  USER_PERMISSIONS,
} from '../shared/constants/general.constants';
import { IUser } from '../shared/models/IUser.model';
import { DataService } from '../shared/services/data.service';
import { HelpersService } from '../shared/services/helpers.service';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user = new BehaviorSubject<IUser>(null);
  user$ = this.user.asObservable();
  constructor(
    private helpers: HelpersService,
    private router: Router,
    private dataService: DataService,
    private rolesService: NgxRolesService,
    private permissionsService: NgxPermissionsService
  ) {}

  setUser(user: IUser): void {
    this.user.next(user);
  }

  fetchUser(): void {
    firstValueFrom(
      this.dataService.get(`${userProfileApi}`).pipe(
        tap((user: IUser) => {
          const permissions = [];
          const roles = user.roles.map((item) => item.key);
          this.permissionsService.addPermission(roles);
          permissions.push(...roles);
          user.resources.forEach((role) => {
            const keys = role.actions.map((action) => role.key + action.type);
            this.permissionsService.addPermission(keys);
            permissions.push(...keys);
          });
          this.helpers.setItemToLocalStorage(USER_PERMISSIONS, permissions);
          this.helpers.setItemToLocalStorage(ROLES, roles);
          this.setUser(user);
        })
      )
    );
  }

  isAuthenticated(): boolean {
    return !!this.helpers.getItemFromLocalStorage(ACCESS_TOKEN);
  }

  signIn(username: string, password: string): Observable<any> {
    this.helpers.removeItemFromLocalStorage(ACCESS_TOKEN);
    this.helpers.removeItemFromLocalStorage(REFRESH_TOKEN);
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password')
      .set('scope', 'evento')
      .set('client_id', '8783e6eb-baf7-4113-bc2f-0fc0dacc7b2d')
      .set('client_secret', 'HIngd4A7YI5Lf7gQgaTG0moerx5mTWfd');

    return this.dataService.post(`${tokenApi}`, body, HTTP_OPTIONS).pipe(
      tap((res) => {
        this.helpers.setItemToLocalStorage(ACCESS_TOKEN, res.access_token);
        this.helpers.setItemToLocalStorage(REFRESH_TOKEN, res.refresh_token);
      })
    );
  }

  refreshToken(refreshToken: string): Observable<any> {
    this.helpers.removeItemFromLocalStorage(ACCESS_TOKEN);
    this.helpers.removeItemFromLocalStorage(REFRESH_TOKEN);
    const body = new HttpParams()
      .set('refresh_token', refreshToken)
      .set('grant_type', 'refresh_token');

    return this.dataService.post(`${tokenApi}`, body, HTTP_OPTIONS).pipe(
      tap((res) => {
        this.helpers.setItemToLocalStorage(ACCESS_TOKEN, res.access_token);
        this.helpers.setItemToLocalStorage(REFRESH_TOKEN, res.refresh_token);
      })
    );
  }

  logout(): void {
    this.helpers.removeItemFromLocalStorage(ACCESS_TOKEN);
    this.helpers.removeItemFromLocalStorage(REFRESH_TOKEN);
    this.rolesService.flushRolesAndPermissions();
    this.router.navigateByUrl('/');
    this.setUser(null);
  }
}
