<nav
  aria-label="Page navigation"
  *ngIf="meta && meta.itemCount > 0 && meta.pageCount > 0"
>
  <ul class="pagination">
    <li class="page-item">
      <button
        class="page-link"
        aria-label="Previous"
        (click)="onPrev()"
        [disabled]="meta.page === 1"
        [ngClass]="{ disabled: meta.page === 1 || loading }"
      >
        <i
          *ngIf="translate.currentLang == 'ar'"
          class="fas fa-chevron-right"
        ></i>
        <i
          *ngIf="translate.currentLang == 'en'"
          class="fas fa-chevron-left"
        ></i>
      </button>
    </li>
    <li class="page-item" *ngFor="let pageNum of getPages()">
      <button
        class="page-link"
        [ngClass]="{ linkActive: pageNum === meta.page }"
        (click)="onPage(pageNum)"
      >
        <span [ngClass]="{ linehight: translate.currentLang == 'ar' }">{{
          pageNum
        }}</span>
      </button>
    </li>
    <li class="page-item">
      <button
        class="page-link"
        aria-label="Next"
        (click)="onNext(true)"
        [disabled]="meta.page === meta.pageCount"
        [ngClass]="{ disabled: meta.page === meta.pageCount }"
      >
        <i
          *ngIf="translate.currentLang == 'ar'"
          class="fas fa-chevron-left"
        ></i>
        <i
          *ngIf="translate.currentLang == 'en'"
          class="fas fa-chevron-right"
        ></i>
      </button>
    </li>
  </ul>
</nav>
