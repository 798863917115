import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toVat',
})
export class ToVATPipe implements PipeTransform {
  transform(number: number): any {

    return Math.round(number * 1.15)
  }
}
