<div class="notificationsList-wrapper">
  <div class="notificationsList-header">
    <h2 class="notificationsList-label">
      الإشعارات
    </h2>
    <button type="button" class="btn notificationsList-allReadableBtn">
      اجعل الكل مقروء

    </button>
  </div>
  <ul class="notificationsList">
    <li class="notificationsList-item is-readable">
      <div class="notificationsList-cover">
        <i class="fas fa-calendar-week icon"></i>
      </div>
      <h3 class="notificationsList--title"> اضف بعض التفاصيل للايفنت
      </h3>
      <p class="notificationsList--date"> منذ دقيقة</p>
      <a href="notificationsList--link"> تفاصيل الفعالية

      </a>
    </li>
    <ng-container *ngFor="let item of [1,2,1,4]">
      <li class="notificationsList-item">
        <div class="notificationsList-cover">
          <i class="fas fa-calendar-week icon"></i>
        </div>
        <h3 class="notificationsList--title"> اضف بعض التفاصيل للايفنت
        </h3>
        <p class="notificationsList--date"> منذ دقيقة</p>
        <a href="notificationsList--link"> تفاصيل الفعالية

        </a>
      </li>
    </ng-container>


  </ul>
</div>
