<div class="ngb-toasts">
  <ngb-toast
    *ngFor="let toast of toast.toasts"
    [class]="toast.classname"
    [autohide]="true"
    [delay]="toast.delay || 3000"
    (hide)="toast.remove(toast)"
  >
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>
    <ng-template ngbToastHeader> </ng-template>

    <ng-template #text>
      <div class="toast-title">
        <i *ngIf="toast.classname === 'toast-error'" class="fas fa-info"></i>
        <i *ngIf="toast.classname === 'toast-success'" class="fas fa-check-circle"></i>
        <h3>{{ toast.title  }}</h3>
      </div>
      <p>{{ toast.textOrTpl }}</p>
    </ng-template>
  </ngb-toast>
</div>
