import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticket-card-horizontal',
  templateUrl: './ticket-card-horizontal.component.html',
  styleUrls: ['./ticket-card-horizontal.component.scss']
})
export class TicketCardHorizontalComponent implements OnInit {
  @Input() ticket: any;
  constructor() { }

  ngOnInit(): void {
  }

}
