import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-evento-file-uploader',
  templateUrl: './evento-file-uploader.component.html',
})
export class EventoFileUploaderComponent implements OnInit {
  @Input() label: string;
  @Input() acceptedFiles: string;
  @Input() multiple: boolean;
  @Output() fileChanged = new EventEmitter<string | ArrayBuffer>(null);
  files: File[] = [];

  constructor() {}

  ngOnInit(): void {}

  fileChangeEvent(event: any): void {
    if (!event.target.files.length) {
      return;
    }
    this.files = event.target.files;
    const file = this.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.fileChanged.emit(reader.result);
    };
    reader.readAsDataURL(file);
  }
}
