<footer *ngIf="!hideFooter" class="footer">
  <nav class="navbar footer-navbar">
    <div class="footer-logo">
      <a class="footer-brand" href="#">
        <img
          *ngIf="translate.currentLang === 'ar'"
          src="/assets/images/evento-logo.svg"
          width="60"
          height="40"
          alt="footer logo"
          aria-label="footer logo"
          class="image"
        />
        <img
          *ngIf="translate.currentLang === 'en'"
          src="/assets/images/evento-logo-en.svg"
          alt=""
          class="image"
        />
      </a>
      <p class="footer-copyRight">
        {{ "All Rights Reserved for Evento" | translate }} © {{ date }}
      </p>
    </div>

    <div class="footer-links">
      <div class="addtional-wrapper">
        <a class="footer-label" routerLink="/vat">
          <img
            width="40"
            height="50"
            alt="vat image"
            src="/assets/images/footer/vat-footer.svg"
          />
        </a>
        <p class="footer-label">{{ "Secure Payment" | translate }}</p>
        <ul
          class="footer-payment flex items-center space-x-.5 rtl:space-x-reverse mb-0"
        >
          <li>
            <img
              width="1000"
              height="325"
              src="assets/images/footer/visa.png"
              alt=""
              class="image"
            />
          </li>
          <li>
            <img
              src="assets/images/footer/mastercard.svg"
              alt=""
              width="200"
              height="325"
              class="image"
            />
          </li>
          <li>
            <img
              width="800"
              height="365"
              src="assets/images/footer/mada.webp"
              alt=""
              class="image"
            />
          </li>
          <li>
            <img
              width="165"
              height="105"
              src="assets/images/footer/apple-pay.svg"
              alt=""
              class="image"
            />
          </li>
        </ul>
      </div>
      <div class="addtional-wrapper">
        <p class="footer-label">{{ "Follow Us" | translate }}</p>
        <ul class="nav footer-social">
          <li class="nav-item">
            <a
              class="nav-link"
              aria-label="twitter"
              href="https://twitter.com/Eventoapps"
              target="_blank"
              ><i class="fab fa-twitter-square"></i>
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              aria-label="instagram"
              href="https://www.instagram.com/eventoapps/"
              target="_blank"
              ><i class="fab fa-instagram-square"></i
            ></a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              aria-label="linkedin"
              href="https://www.linkedin.com/company/eventoapps/"
              target="_blank"
              ><i class="fab fa-linkedin"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</footer>
