import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-evento-select',
  templateUrl: './evento-select.component.html',
})
export class EventoSelectComponent implements OnInit {
  @Input() eventoFormControl: AbstractControl;
  @Input() items: any[];
  @Input() compareWith;
  @Input() multiple: boolean;
  @Input() placeholder?: string;
  @Input() requiredMessage?: string;
  @Input() label?: string;
  @Input() hasStaticValues?: boolean;
  @Input() requiredQuestionFlag? = false;
  @Output() reachedEnd = new EventEmitter<void>();
  @Output() changeEvent = new EventEmitter<String>();

  constructor() {}

  ngOnInit(): void {}
  get formControl(): UntypedFormControl {
    return this.eventoFormControl as UntypedFormControl;
  }

  reachedEndEvent() {
    this.reachedEnd.emit();
  }

  onChange(e) {
    return this.changeEvent.emit(e);
  }
}
