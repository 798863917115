<div class="modal-body">
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="aspectRatio"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady($event)"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary"
    (click)="activeModal.dismiss(croppedImage)"
  >
    {{ "Save" | translate }}
  </button>
</div>

<!-- <div class="image-cropper-actions">
    <button class="btn" (click)="rotateLeft()">Rotate left</button>
    <button class="btn" (click)="rotateRight()">Rotate right</button>
    <button class="btn" (click)="flipHorizontal()">Flip horizontal</button>
    <button class="btn" (click)="flipVertical()">Flip vertical</button>
    <button class="btn" (click)="toggleContainWithinAspectRatio()">
      {{
        containWithinAspectRatio
          ? "Fill Aspect Ratio"
          : "Contain Within Aspect Ratio"
      }}
    </button>
    <button class="btn" (click)="resetImage()">Reset image</button>
    <input
      [(ngModel)]="rotation"
      placeholder="Rotation"
      type="number"
      (keyup)="updateRotation()"
    />
    <button class="btn" (click)="zoomOut()">Zoom -</button>
    <button class="btn" (click)="zoomIn()">Zoom +</button>
  </div> -->
