import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  date = new Date().getFullYear();
  hideFooter = false;
  constructor(public translate: TranslateService, private router: Router) {}

  ngOnInit(): void {
    if (this.router.url.includes('sales/list/book')) {
      this.hideFooter = true;
    }
    if (window.screen.width <= 768) {
      if (this.router.url.includes('event-details')) {
        this.hideFooter = true;
      }
    }
  }
}
