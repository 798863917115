<ng-container *ngIf="user$ | async as user">
  <div
    class="email-not-verified"
    *ngIf="
      !user.emailVerified &&
      !router.url.includes('auth') &&
      !router.url.includes('404')
    "
  >
    {{ "Email is not verified" | translate }}.
    <a href="#" (click)="$event.preventDefault(); resendActivationLink()">{{
      "Resend activation link" | translate
    }}</a>
  </div>
</ng-container>

<router-outlet></router-outlet>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<app-toasts-container></app-toasts-container>
