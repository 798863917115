import {
  Component,
  Input,
  OnDestroy,
  AfterViewInit,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from '@angular/core';
import { EventInput } from '@fullcalendar/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { firstValueFrom, Subscription, tap } from 'rxjs';
import { eventsApi } from '../../constants/api.constants';
import { ISchedule } from '../../models/ISchedule.model';
import { DataService } from '../../services/data.service';
import { AddDatesModalComponent } from '../add-dates-modal/add-dates-modal.component';
import dayGridPlugin from '@fullcalendar/daygrid';
import { DatePipe, DOCUMENT } from '@angular/common';
import { Calendar } from 'fullcalendar';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from '../../services/toast-service';
import { Toast } from '../../enums/toast';

@Component({
  selector: 'app-event-calendar-widget',
  templateUrl: './event-calendar-widget.component.html',
  styleUrls: ['./event-calendar-widget.component.scss'],
})
export class EventCalendarWidgetComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() eventId: string;
  events: any[] = [];
  isRTL: boolean;
  subs: Subscription[] = [];
  eventSchedules: ISchedule[];

  calendar: Calendar;
  @ViewChild('calendarEl', { static: false }) calendarEl: ElementRef;

  constructor(
    private modalService: NgbModal,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private toast: ToastService
  ) {}

  ngAfterViewInit(): void {
    this.calendar = new Calendar(this.calendarEl.nativeElement, {
      plugins: [dayGridPlugin],
      initialView: 'dayGridMonth',
      events: [...this.events],
      eventContent: (arg) => {
        const { event } = arg;
        const deleteTimeButton = this.document.createElement('button');
        deleteTimeButton.innerHTML = `<i class="fas fa-times"></i>`;
        deleteTimeButton.setAttribute('type', 'button');
        deleteTimeButton.classList.add('btn');
        const calDayTimes = this.document.createElement('div');
        calDayTimes.classList.add('calday');
        deleteTimeButton.onclick = () => {
          return this.deleteDate(event.id);
        };
        const template = `
              <div>
                ${event.title}
              </div>
              `;

        calDayTimes.innerHTML = template;
        calDayTimes.children[0].append(deleteTimeButton);
        const activeButtonDiv = this.document.createElement('div');
        activeButtonDiv.onclick = () => {
          return this.toggleDateStatus(event.id, event.extendedProps.status);
        };
        event.extendedProps.status == 'ACTIVE'
          ? activeButtonDiv.classList.add('activeBtn')
          : activeButtonDiv.classList.add('inactiveBtn');
        const templateDiv = `
              <div>
                ${
                  event.extendedProps.status == 'ACTIVE'
                    ? this.translate.currentLang == 'ar'
                      ? 'إلغاء التفعيل'
                      : 'InActivate'
                    : this.translate.currentLang == 'ar'
                    ? 'تفعيل'
                    : 'Activate'
                }
              </div>
              `;
        activeButtonDiv.innerHTML = templateDiv;
        const parentDiv = this.document.createElement('div');
        parentDiv.appendChild(calDayTimes);
        parentDiv.appendChild(activeButtonDiv);
        event.extendedProps.status == 'INACTIVE'
          ? parentDiv.classList.add('fc-red')
          : parentDiv.classList.add('fc-success');

        return { domNodes: [parentDiv] };
      },
    });

    this.calendar.render();
  }
  ngOnInit(): void {
    this.getEventSchedule();
    this.subs.push(
      this.translate.onLangChange.subscribe((lang: LangChangeEvent) => {
        if (lang.lang === 'ar') {
          this.isRTL = true;
          return;
        }
        this.isRTL = false;
      })
    );
  }

  deleteDate(id: string): any {
    firstValueFrom(
      this.dataService
        .delete(`${eventsApi}/${this.eventId}/schedules/${id}`, {
          headers: { 'Accept-Language': this.translate.currentLang },
        })
        .pipe(
          tap((ـ) => {
            this.spinner.hide();
            this.toast.show('Success', 'Deleted', {
              classname: Toast.success,
            });
            this.getEventSchedule();
          })
        )
    );
  }

  toggleDateStatus(id: string, status: string): any {
    const changedStatus = status == 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    firstValueFrom(
      this.dataService
        .patch(
          `${eventsApi}/${this.eventId}/schedules/${id}/${changedStatus}`,
          {}
        )
        .pipe(
          tap((ـ) => {
            this.spinner.hide();
            this.toast.show(
              'Success',
              this.translate.currentLang == 'ar'
                ? 'تم تغيير الحالة'
                : 'Status changed',
              {
                classname: Toast.success,
              }
            );
            this.getEventSchedule();
          })
        )
    );
  }

  getEventSchedule(): void {
    this.spinner.show();
    firstValueFrom(
      this.dataService.get(`${eventsApi}/${this.eventId}/schedules`).pipe(
        tap((res: ISchedule[]) => {
          if (res.length) {
            this.eventSchedules = res;
            this.calendar.removeAllEvents();
            res.forEach((item: any) => {
              const start = new Date(item.startAt * 1000);
              const end = new Date(item.endAt * 1000);
              const startDay = start.getDate();
              const endDay = end.getDate();
              const isSameDay = startDay === endDay;

              const event: EventInput = {
                title: isSameDay
                  ? `${new DatePipe('en').transform(
                      start,
                      'h:mm a'
                    )} - ${new DatePipe('en').transform(end, 'h:mm a')}`
                  : end
                  ? `${new DatePipe('en').transform(
                      start,
                      'MMM d h:mm a'
                    )} - ${new DatePipe('en').transform(end, 'MMM d h:mm a')}`
                  : `${new DatePipe('en').transform(start, 'MMM d h:mm a')}`,
                start,
                end,
                id: item.id,
                status: item.status,
              };
              // this.events = [...this.events, event];
              this.calendar.addEvent(event);
            });
          } else {
            this.spinner.hide();
          }
          this.spinner.hide();
        })
      )
    );
  }

  addDates(): void {
    const modalRef = this.modalService.open(AddDatesModalComponent);
    modalRef.componentInstance.eventId = this.eventId;
    modalRef.componentInstance.eventSchedules = this.eventSchedules;
    this.subs.push(
      modalRef.closed.subscribe((result) => {
        if (result) {
          this.getEventSchedule();
          // this.refresh.next();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
