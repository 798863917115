import { Injectable, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];
  constructor(private translate: TranslateService) {}

  async show(
    title: string,
    textOrTpl: string,
    options: any = {}
  ): Promise<any> {
    const titleTrans = await firstValueFrom(this.translate.get(title));
    const textTrans = await firstValueFrom(this.translate.get(textOrTpl));
    this.toasts.push({ title: titleTrans, textOrTpl: textTrans, ...options });
  }

  remove(toast: any): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
