import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from './toast-service';
import { Toast } from '../enums/toast';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private toast: ToastService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        this.spinner.hide();
        let errorMessage = err.error?.message;
        if (errorMessage instanceof Array) {
          let validationMessage =
            errorMessage[0].constraints[
              Object.keys(errorMessage[0].constraints)[0]
            ];
          errorMessage = errorMessage[0].message
            ? errorMessage[0].message
            : validationMessage;
        }

        if (
          err.status === 400 ||
          err.status === 403 ||
          err.status === 503 ||
          err.status === 0 ||
          err.status === 404
        ) {
          if (errorMessage) {
            this.toast.show('Error', errorMessage, {
              classname: Toast.error,
              delay: 5000,
            });
            // if (this.router.url.includes('event-details')) {
            //   this.router.navigate(['/home']);
            // }
          }
        } else if (err.status === 401) {
          // AuthenticationError
          this.authService.logout();
          const queryParams = {
            redirect: this.router.url,
          };
          this.router.navigate(['/auth/login'], {
            queryParams,
          });
          this.toast.show('Error', errorMessage, {
            classname: Toast.error,
            delay: 5000,
          });
          this.authService.logout();
        } else if (err.status === 500) {
          this.toast.show('Error', 'error occured', {
            classname: Toast.error,
            delay: 5000,
          });
        }
        return throwError(() => new Error(err));
      })
    );
  }
}
