<div class="event-calendar">
  <div class="event-calendar__header">
    <button
      class="btn btn-primary title-action"
      (click)="addDates()"
      *ngxPermissionsOnly="['eventScheduleCREATE']"
    >
      <i class="fas fa-plus mr-3 align-middle"></i> {{ 'Add Date' | translate }}
    </button>
  </div>
    <div
      class="event-calendar__body"
      [attr.dir]="isRTL? 'rtl' : 'ltr'"
    >
      <div #calendarEl></div>
    </div>
  </div>

