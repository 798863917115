import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import {
  ACCESS_TOKEN, ROLES,
  USER_PERMISSIONS,
} from '../shared/constants/general.constants';
import { Toast } from '../shared/enums/toast';
import { HelpersService } from '../shared/services/helpers.service';
import { ToastService } from '../shared/services/toast-service';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private helpers: HelpersService,
    private toast: ToastService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private translate: TranslateService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = this.helpers.getItemFromLocalStorage(ACCESS_TOKEN);
    if (token) {
      if (this.helpers.checkItemFromLocalStorage(USER_PERMISSIONS)) {
        this.permissionsService.loadPermissions(
          this.helpers.getItemFromLocalStorage(USER_PERMISSIONS)
        );
      }
      return true;
    } else {
      this.translate.setDefaultLang('ar')
      this.toast.show(
        this.translate.instant('Not Authorized'),
        this.translate.instant('You are not authorized to access this page, Please login first!'),
        {
          classname: Toast.error,
        }
      );
      this.router.navigateByUrl('/auth');
      return false;
    }
  }
}
