import { TruncateWordsPipe } from './pipes/truncateWords.pipe';
import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NotFoundComponent } from 'src/app/shared/components/not-found/not-found.component';
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';
import { RouterModule } from '@angular/router';
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbPopoverModule,
  NgbTimepickerModule,
  NgbToastModule,
} from '@ng-bootstrap/ng-bootstrap';
import { EventCardComponent } from './components/event-card/event-card.component';
import { PackageCardComponent } from 'src/app/shared/components/package-card/package-card.component';
import { TitleAndDescComponent } from './components/title-and-desc/title-and-desc.component';
import { SponsorsComponent } from './components/sponsors/sponsors.component';
import { NodataComponent } from './components/nodata/nodata.component';
import { Wizard2StepsComponent } from './components/wizard2-steps/wizard2-steps.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { TicketCardWithWrapperComponent } from './components/ticket-card-with-wrapper/ticket-card-with-wrapper.component';
import { TicketCardHorizontalComponent } from './components/ticket-card-horizontal/ticket-card-horizontal.component';
import { ResponsiveTableDirective } from './directives/responsive-table/responsive-table.directive';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { MapSearchComponent } from './map-search/map-search.component';
import { ErrorInputComponent } from './components/error-input/error-input.component';
import { EventoInputComponent } from './components/evento-forms/evento-input/evento-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { EventoSelectComponent } from './components/evento-forms/evento-select/evento-select.component';
import { EventoCropUploaderComponent } from './components/evento-forms/evento-crop-uploader/evento-crop-uploader.component';
import { NgxEditorModule } from 'ngx-editor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AgmCoreModule } from '@agm/core';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { CropperModalComponent } from './components/evento-forms/cropper-modal/cropper-modal.component';
import { CalendarModule } from 'angular-calendar';
import { AddDatesModalComponent } from './components/add-dates-modal/add-dates-modal.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { EventCalendarWidgetComponent } from './components/event-calendar-widget/event-calendar-widget.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CustomFormsModule } from 'ngx-custom-validators';
import { FormatHourPipe } from './pipes/12-format-hour.pipe';
import { FileSaverModule } from 'ngx-filesaver';
import { EventoFileUploaderComponent } from './components/evento-forms/evento-file-uploader/evento-file-uploader.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ToVATPipe } from './pipes/to-vat.pipe';
import { LoginPopupComponent } from './components/login-popup/login-popup.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { BookTitleComponent } from './components/book-title/book-title.component';
import { EventoDocumentUploaderComponent } from './components/evento-forms/evento-document-uploader/evento-document-uploader.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { EventoImageMultipleUploaderComponent } from './components/evento-forms/evento-image-multiple-uploader/evento-image-multiple-uploader.component';
import { ReportCardComponent } from './components/report-card/report-card.component';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { EventoTableComponent } from './components/evento-table/evento-table.component';

const sharedMComponents: any[] | Type<any> = [
  FooterComponent,
  NotFoundComponent,
  EventCardComponent,
  PackageCardComponent,
  TitleAndDescComponent,
  SponsorsComponent,
  NodataComponent,
  Wizard2StepsComponent,
  NodataComponent,
  BreadcrumbComponent,
  TicketCardWithWrapperComponent,
  TicketCardHorizontalComponent,
  ResponsiveTableDirective,
  NotificationsComponent,
  EventoSelectComponent,
  EventoFileUploaderComponent,
  EventoCropUploaderComponent,
  EventoDocumentUploaderComponent,
  ErrorInputComponent,
  EventoInputComponent,
  LocalizedDatePipe,
  SafeHTMLPipe,
  BookTitleComponent,
  ShortNumberPipe,
  AddDatesModalComponent,
  EventCalendarWidgetComponent,
  PaginationComponent,
  FormatHourPipe,
  ToVATPipe,
  SafeUrlPipe,
  EventoImageMultipleUploaderComponent,
  ReportCardComponent,
  CustomCurrencyPipe,
  SortPipe,
  TruncateWordsPipe,
  FilterPipe,
  EventoTableComponent,
];
const ngBootstrapModules: any[] | Type<any> = [
  NgbAccordionModule,
  NgbCollapseModule,
  NgOtpInputModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbToastModule,
  NgbDatepickerModule,
  NgbPopoverModule,
  NgbTimepickerModule,
  NgxPermissionsModule,
];
const sharedModules: any[] | Type<any> = [
  CommonModule,
  TranslateModule,
  ngBootstrapModules,
  ReactiveFormsModule,
  FormsModule,
  SwiperModule,
  NgSelectModule,
  NgxEditorModule,
  ImageCropperModule,
  NgxSpinnerModule,
  NgxIntlTelInputModule,
  AgmCoreModule,
  CalendarModule,
  FlatpickrModule,
  CustomFormsModule,
  FileSaverModule,
  ClipboardModule,
];
@NgModule({
  declarations: [
    sharedMComponents,
    MapSearchComponent,
    CropperModalComponent,
    LoginPopupComponent,
  ],
  imports: [RouterModule, sharedModules],
  exports: [sharedMComponents, sharedModules],
})
export class SharedModule {}
