import { Component, Input, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import SwiperCore, { Navigation } from 'swiper';
// install Swiper modules
SwiperCore.use([Navigation]);
@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss'],
})
export class SponsorsComponent implements OnInit {
  @Input() sponsors: any[];
  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: true,
    breakpoints: {
      '640': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '768': {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      '1024': {
        slidesPerView: 5,
        spaceBetween: 50,
      },
    },
    autoplay: {
      delay: 5000,
    },
  };
  constructor() {}

  ngOnInit(): void {}
}
