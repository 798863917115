import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class EventService {
  selectedEventUpdated = new BehaviorSubject<boolean>(false);
  selectedEvent = new BehaviorSubject<any>(null);
  selectedEvent$ = this.selectedEvent.asObservable();

  newEvent = new BehaviorSubject<any>(null);
  newEvent$ = this.newEvent.asObservable();

  newEventSteps = new BehaviorSubject<any>(null);
  newEventSteps$ = this.newEventSteps.asObservable();

  currentEventStep = new BehaviorSubject<number>(0);
  currentEventStep$ = this.currentEventStep.asObservable();
  constructor() {}

  updateData(formValue: object): void {
    const currentValue = this.newEvent.getValue();
    const newValue = { ...currentValue, ...formValue };
    this.newEvent.next(newValue);
  }

  resetNewEvent(): void {
    this.newEvent.next({
      mainInfo: null,
      isMainInfoForm: false,
      activatedSteps: {
        1: 1,
      },
    });
  }
}
