import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'customCurrency' })
export class CustomCurrencyPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(
    value: number,
    currencyCode: string = 'SAR',
    symbolDisplay: boolean = true
  ): string {
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: symbolDisplay ? 'symbol' : 'code',
    }).format(value);

    const [symbol, amount] = formattedValue.split(/(\d+)/);
    const translateCurrency = this.translate.instant('SR');
    if (this.translate.currentLang === 'ar') {
      return `${amount} <span class="text-success"> ${translateCurrency} </span> `;
    } else {
      return `<span class="text-success"> ${translateCurrency} </span> ${amount}`;
    }
  }
}
