import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-book-title',
  templateUrl: './book-title.component.html',
  styleUrls: ['./book-title.component.scss'],
})
export class BookTitleComponent {
  @Input() booktitle;
  @Input() info;
  isMobile = false;
  constructor() {
    if (window.screen.width <= 768) {
      this.isMobile = true;
    }
  }
}
