import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelpersService } from './helpers.service';
import { ACCESS_TOKEN } from '../constants/general.constants';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './language.service';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private helpers: HelpersService,
    private languageService: LanguageService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.helpers.getItemFromLocalStorage(ACCESS_TOKEN);
    const headers: any = {};
    //disable cache in broswer
    headers['Cache-Control'] =
      'no-cache, no-store, must-revalidate, post-check=0, pre-check=0';
    headers['Pragma'] = 'no-cache';
    headers['Expires'] = '0';

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    headers['Access-Language'] = this.languageService.getCurrnetLang();

    request = request.clone({
      setHeaders: headers,
    });
    return next.handle(request);
  }
}
