export enum eventStatus {
  UNDER_REVIEW = 'Under review',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  DELETED = 'Deleted',
  CLOSED = 'Closed',
}

export function getEventStatusKeyByValue(enumValue: string): string {
  let keys = Object.keys(eventStatus).filter(
    (x) => eventStatus[x] == enumValue
  );
  return keys.length > 0 ? keys[0] : '';
}
