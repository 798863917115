import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.scss'],
})
export class ReportCardComponent {
  @Input() status: string;
  @Input() count: number;
  @Input() icon: string;
  @Input() weekDiffPercentage: number;
  @Input() trendIcon: string;
  constructor() {}
}
