<div class="modal-header border-0">
  <h4 class="modal-title">{{ 'Add Date' | translate }}</h4>
  <button type="button" class="close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="addDatesForm">
    <div class="form-group flex-column">
      <app-evento-input [eventoFormControl]="addDatesForm.get('startDate')" type="datetimepicker"
        requiredMessage="{{ 'Required Field' | translate }}" label="{{ 'Start Date' | translate }}"
        placeholder="{{ 'Start Date' | translate }}" [minDate]="today"></app-evento-input>
    </div>
    <div class="form-group flex-column">
      <app-evento-input [eventoFormControl]="addDatesForm.get('endDate')" type="datetimepicker"
        [minDate]="addDatesForm.value.startDate" requiredMessage="{{ 'Required Field' | translate }}"
        label="{{ 'Expiry date' | translate }}" placeholder="{{ 'Expiry date' | translate }}"></app-evento-input>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="activeModal.close()">
    {{ "Cancel" | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="addDates()">
    {{ "Save" | translate }}
  </button>
</div>
