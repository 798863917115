  <div class="packageCard {{type}}">

    <h3 class="packageCard-title">الباقة البرونزية</h3>
    <h2 class="packageCard-price">مجانية</h2>

    <button type="button" class="btn packageCard-enterBtn">الباقة البرونزية <i class="fas fa-plus"></i></button>

    <ul class="packageCard-list">
      <li class="packageCard-listItem"> لوحة تحكم خاصة بفعاليتك</li>
      <li class="packageCard-listItem"> خدمات التسجيل وبيع التذاكر</li>
      <li class="packageCard-listItem"> إمكانية التحكم بخصوصية الفعالية</li>
      <li class="packageCard-listItem"> إضافة نوع واحد من التذاكر</li>
      <li class="packageCard-listItem"> إضافة موظف مبيعات واحد فقط</li>
      <li class="packageCard-listItem"> إضافة مشرف واحد فقط</li>
      <li class="packageCard-listItem"> إمكانية تحميل بيانات والإحصائيات</li>
      <li class="packageCard-listItem"> إحصائيات لحظية عن الفعالية</li>
      <li class="packageCard-listItem"> لوحة تحكم للمشرفين وموظفين المبيعات.</li>
    </ul>

  </div>
