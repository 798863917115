<div class="ticketWithWrapper" *ngIf="ticket">
  <div class="row">
    <div class="col-12">
      <div class="ticketWithWrapper-left">
        <div class="imgWrapper">
          <img
            [src]="[
              'https://api.qrserver.com/v1/create-qr-code/?data=' +
                ticket.id +
                '&margin=30&height=100'
            ]"
            class="image"
            alt="..."
          />
        </div>
        <div class="contentWrapper">
          <h5 class="mb-1">
            {{ helpers.getElementTranslatedName(ticket.event) }}
          </h5>
          <p class="mb-2">
            <small class="text-muted"
              >الثلاثاء - 1 يناير 2021 - 01:30 مساء</small
            >
          </p>
          <div class="eventsCards-btns">
            <span class="eventsCards-location">
              <i class="fas fa-map-marker-alt"></i>
              {{ ticket.event.addressEn }}</span
            >
            <span class="eventCard-type">فنون و ثقافة</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-4">
      <h4>{{ "Tickets" | translate }}</h4>
      <div class="grid grid-cols-[repeat(auto-fill,minmax(300px,_1fr))] gap-3">
        <app-ticket-card-horizontal></app-ticket-card-horizontal>
      </div>
    </div>
  </div>
</div>
