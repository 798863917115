import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';

@Component({
  selector: 'app-evento-input',
  templateUrl: './evento-input.component.html',
  styleUrls: ['./evento-input.component.scss'],
})
export class EventoInputComponent implements OnInit {
  @Input() eventoFormControl: AbstractControl;
  @Input() placeholder: string;
  @Input() requiredMessage?: string;
  @Input() type?: string;
  @Input() minlengthMessage?: string;
  @Input() maxlengthMessage?: string;
  @Input() patternMessage?: string;
  @Input() validatePhoneNumberMessage?: string;
  @Input() maxMessage?: string;
  @Input() minMessage?: string;
  @Input() minDateMessage?: string;
  @Input() maxDateMessage?: string;
  @Input() emailMessage?: string;
  @Input() mustMatchMessage?: string;
  @Input() icon?: string;
  @Input() iconCustom?: string;
  @Input() label?: string;
  @Input() readonly?: boolean;
  @Input() markDisabled?: any;
  @Input() minDate?: any;
  @Input() maxDate?: any;
  @Input() country?: any;
  @Input() min?: any;
  @Input() max?: any;
  @Input() greaterThanMinMessage?: any;
  @Input() onlyWhitespaceMessage?: any;
  @Input() requiredQuestionFlag? = false;
  @Input() searchByIcon = false;
  @Output() iconClick = new EventEmitter<void>();
  nextArrow = '<i class="fas fa-arrow-right"></i>';
  prevArrow = '<i class="fas fa-arrow-left"></i>';

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.SaudiArabia];
  PhoneNumberFormat = PhoneNumberFormat;
  viewPassword = false;
  constructor() {}

  ngOnInit(): void {}

  get formControl(): UntypedFormControl {
    return this.eventoFormControl as UntypedFormControl;
  }

  onSearchIconClick() {
    this.iconClick.emit();
  }
}
