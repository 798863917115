<label *ngIf="label">
  {{ label }}
</label>

<div
  class="uploadWrapper"
  [class]="ratio"
  [class.has-image]="imgSrc || croppedImg"
>
  <input type="file" id="file-input" (change)="fileChangeEvent($event)" />
  <img [src]="croppedImg || imgSrc" *ngIf="croppedImg || imgSrc" alt="" />
  <label for="file-input">
    <div class="iconWrapper"><i class="fas fa-upload fa-3x"></i></div>
    <p class="browse">{{ "Drop the picture here" | translate }} {{ "or browse files" | translate }}</p>
  </label>
  <div class="actions">
    <button type="button" class="btn" *ngIf="croppedImg" (click)="changeCrop()">
      {{ "Crop Again" | translate }}
    </button>
    <label for="file-input" *ngIf="imgSrc || croppedImg" class="btn">{{
      "Change" | translate
    }}</label>
  </div>
</div>
