<div
  title="{{ booktitle | translate }}"
  class="d-flex justify-content-between mobile-title"
>
  <p class="book-now mb-0">{{ booktitle | translate }}</p>
  <div *ngIf="info && !isMobile" class="d-flex align-items-center">
    <img class="info-img" src="assets/images/events/info-circle.png" />
    <span class="info-text">{{ info | translate }}</span>
  </div>
</div>
