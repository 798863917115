import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatHour',
})
export class FormatHourPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    const parts = value?.split(':');
    const hour = +parts[0];
    return hour > 12 ? String(hour - 12) + ' ' + 'م' : String(hour) + ' ' + 'ص';
  }
}
