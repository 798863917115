import { Directive, ElementRef, Renderer2, ViewChild } from '@angular/core';

@Directive({
  selector: '.table-default'
})
export class ResponsiveTableDirective {
  private labels = []
  constructor(private el: ElementRef,
    private renderer: Renderer2) {
    // renderer.listen(renderElement, name, callback)
    // renderer.setStyle(el.nativeElement, 'backgroundColor', 'gray');
    // renderer.setAttribute (el.nativeElement, 'data-label', 'gray');
  }
  ngOnInit(): void {

    this.el.nativeElement.querySelectorAll('thead tr th').forEach(element => {
      this.labels.push(element.textContent)
    });

  }
  ngAfterViewInit(): void {
    this.el.nativeElement.querySelectorAll('tbody tr').forEach(trElem => {
      trElem.querySelectorAll('td').forEach((tdElem, idx) => {
        if (idx === 0) {
          const iconElem = this.renderer.createElement('i');
          this.renderer.addClass(iconElem, "fas");
          this.renderer.addClass(iconElem, "table-minimizeToggle");
          this.renderer.listen(iconElem, 'click', (event) => {
            if (event.target.parentNode.classList.contains('is-closed')) {
              this.renderer.removeClass(event.target.parentNode, "is-closed");
            } else {
              this.renderer.addClass(event.target.parentNode, "is-closed");

            }
          })
          this.renderer.appendChild(tdElem, iconElem);
        }
        this.renderer.setAttribute(tdElem, 'data-label', this.labels[idx]);
      });
    });
  }

}
