import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IPublicEvent } from '../../models/IPublic-Event.model';
import { HelpersService } from '../../services/helpers.service';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit {
  @Input() event: IPublicEvent;

  constructor(
    public helpers: HelpersService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {}
}
