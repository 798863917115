import { Component, Input, OnInit } from '@angular/core';
import { HelpersService } from '../../services/helpers.service';

@Component({
  selector: 'app-ticket-card-with-wrapper',
  templateUrl: './ticket-card-with-wrapper.component.html',
  styleUrls: ['./ticket-card-with-wrapper.component.scss'],
})
export class TicketCardWithWrapperComponent implements OnInit {
  @Input() ticket: any;
  constructor(public helpers: HelpersService) {}

  ngOnInit(): void {}
}
