import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, Observable, Subscription } from 'rxjs';
import { EventService } from 'src/app/dashboard/new-event/event.service';
import { IEventStep } from 'src/app/dashboard/new-event/IEvent-step.model';

@Component({
  selector: 'app-wizard2-steps',
  templateUrl: './wizard2-steps.component.html',
})
export class Wizard2StepsComponent implements OnInit, OnDestroy {
  steps$: Observable<IEventStep[]>;
  newEvent: any;
  currentEventStep: number;
  subs: Subscription[] = [];
  createdEventId$: Observable<string>;

  constructor(
    private eventService: EventService,
    private route: ActivatedRoute
  ) {
    const steps = [
      {
        name: 'basic information',
        route: '/dashboard/new-event/info',
      },
      { name: 'Event details', route: '/dashboard/new-event/details' },
      {
        name: 'event calendar',
        route: '/dashboard/new-event/calendar',
      },
      { name: 'event photo', route: '/dashboard/new-event/event-image' },
      { name: 'Tickets', route: '/dashboard/new-event/tickets' },
      { name: 'publishing', route: '/dashboard/new-event/publish' },
    ];
    this.eventService.newEventSteps.next(steps);
  }

  ngOnInit(): void {
    this.createdEventId$ = this.route.queryParams.pipe(
      map((res) => res.eventId)
    );
    this.steps$ = this.eventService.newEventSteps$;
    this.subs.push(
      this.eventService.newEvent$.subscribe((res) => (this.newEvent = res))
    );
    this.subs.push(
      this.eventService.currentEventStep$.subscribe(
        (currentEventStep) => (this.currentEventStep = currentEventStep)
      )
    );
  }

  goToStep(stepNo: number): void {
    this.eventService.currentEventStep.next(stepNo - 1);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
