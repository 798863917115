<div class="report-card">
  <div class="report-card__row">
    <div class="report-card__count">{{ count }}</div>
    <img [src]="icon" alt="{{ status }} icon" class="report-card__icon" />
  </div>
  <div class="report-card__status">{{ status | translate }}</div>
  <div class="report-card__trend">
    <img [src]="trendIcon" alt="trend icon" class="report-card__trend-icon" />
    <div class="report-card__trend-text">
      <span *ngIf="weekDiffPercentage > 0"> + </span>
      {{ weekDiffPercentage }} % {{ "Report.ThisWeek" | translate }}
    </div>
  </div>
</div>
