<div class="image-uploader-container">
  <div class="image-uploader-header">
    <button class="btn btn-primary" type="button" (click)="filePicker.click()">
      {{ "UploadPhotos" | translate }}
    </button>
    <input type="file" #filePicker (change)="onImagesPicked($event)" multiple />
    <span class="image-error" *ngIf="imageError">{{ imageError }}</span>
  </div>

  <div
    class="image-preview-container"
    *ngIf="base64ImgArray?.length > 0 || ticketPhotos?.length > 0"
  >
    <div
      class="image-preview"
      *ngFor="let img of base64ImgArray; let i = index"
    >
      <img [src]="img.url" alt="Image {{ i + 1 }}" class="preview-img" />
      <button (click)="removeImage(i)" class="btn btn-danger btn-sm remove-btn">
        X
      </button>
    </div>
    <div class="image-preview" *ngFor="let img of ticketPhotos; let i = index">
      <img [src]="img?.url" alt="Image {{ i + 1 }}" class="preview-img" />
      <button
        (click)="removeImage(i, true)"
        class="btn btn-danger btn-sm remove-btn"
      >
        X
      </button>
    </div>
  </div>
</div>
