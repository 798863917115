<body>
  <!-- Disk Top View -->
  <div class="table-responsive">
    <table class="table invitations-table collapsedTable">
      <thead class="table-header text-center">
        <tr>
          <th scope="col" *ngFor="let header of headers">
            {{ header | translate }}
          </th>
        </tr>
      </thead>
      <tbody class="table-body text-center">
        <tr *ngFor="let row of data " class="text-center">
          <td *ngFor="let column of row.columns" scope="col" style="text-align: -webkit-center">
            <ng-container [ngSwitch]="column.type">
              <!-- Header for Image -->
              <ng-container *ngSwitchCase="'IMAGE'">
                <img [src]="column.value" alt="image" style="width: 50px; height: 50px;" />
              </ng-container>
              <!-- Header for name -->
              <ng-container *ngSwitchCase="'TEXT'">
                <!-- if TEXT === link -->
                <ng-container *ngIf="column.routerLink || column.link; else newDeb">
                  <ng-container *ngIf="column.routerLink">
                    <p style="cursor: pointer" (click)="onClick(column.routerLink)">
                      {{ !column.translatedValue ?
                      column.value : column.value | translate}}</p>
                  </ng-container>
                  <ng-container *ngIf="column.link">
                    <a [href]="column.link" target="_blank" rel="noopener noreferrer">
                      {{ !column.translatedValue ? column.value : column.value | translate }}
                    </a>
                  </ng-container>
                </ng-container>
                <ng-template #newDeb>
                  <p [ngStyle]="{'direction':column.direction}">
                    {{ !column.translatedValue ? column.value : column.value | translate}}
                  </p>

                </ng-template>
              </ng-container>

              <!-- Header for Currency -->
              <ng-container *ngSwitchCase="'CURRENCY'">
                <span [innerHTML]="Number(column.value) | customCurrency"></span>
              </ng-container>

              <!-- Header for Date -->
              <ng-container *ngSwitchCase="'DATE'">
                {{ column.value | date }}
              </ng-container>

              <!-- Header for Status -->
              <ng-container *ngSwitchCase="'STATUS'">
                <div class="status-box {{ column.class}}">
                  {{ !column.translatedValue ? column.value : column.value | translate }}
                </div>
              </ng-container>

              <!-- Header for Actions types -->
              <!-- Delete -->
              <ng-container *ngSwitchCase="'ACTION'">
                <div ngbDropdown class="d-inline-block">
                  <button class="btn p-0 text-primary dropdown-noArrow" id="ticketActions" ngbDropdownToggle>
                    <i class="fas fa-ellipsis-h"></i>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="ticketActions">
                    <ng-container *ngFor="let action of column.actions">
                      <button *ngIf="action.show" ngbDropdownItem
                        (click)="onButtonValueChange(action.translatedValue, row.id)" (click)="action.onClick()">
                        <i class="{{ action.faStyle }}"></i>
                        <span class="mr-2 ml-2">{{ "change-status-to" | translate }} {{ action.translatedValue |
                          translate
                          }}</span>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <!-- VIEW -->
              <ng-container *ngSwitchCase="'VIEW'">
                <a [routerLink]="[column.routerLink]"><i class="far fa-eye"></i> {{ "View" |
                  translate }}
                </a>
              </ng-container>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- ################################################################## -->
  <!--  Mobile View -->

  <div class="mobileView">
    <div class="collapsedCard mt-4 text-center" *ngFor="let row of data; let i = index">
      <div class="collapse-header d-flex flex-wrap justify-content-between text-center" [id]="'accordion-' + i"
        (click)="toggleAccordion(i)">
        <div class="text-center">
          <h6> {{ row.columns[0].value | truncateWords:35}} </h6> <!-- >>>> The Name of Accordion from the data -->
        </div>
        <div class="text-center">
          <i [@rotatedState]="openedAccordions[i] ? 'rotated' : 'default'" class="fas fa-chevron-up"></i>
        </div>
      </div>
      <div [@smoothCollapse]="openedAccordions[i] ? 'finale' : 'initial'" class="table-responsive collapse-body">
        <div class="table m-1  invitations-table">
          <tbody class="table-body d-flex flex-wrap justify-content-between">
            <!-- right side -->
            <tr class=" d-flex flex-column flex-wrap justify-content-between text-start w-50">
              <th scope="col" *ngFor="let header of headers">
                {{ header | translate }}
              </th>
            </tr>

            <!-- left side -->
            <div class=" d-flex flex-column flex-wrap justify-content-between text-start w-50">
              <tr *ngFor="let column of row.columns">
                <th scope="col">
                  <ng-container [ngSwitch]="column.type">
                    <!-- Header for Image -->
                    <ng-container *ngSwitchCase="'IMAGE'">
                      <img [src]="column.value" alt="image" style="width: 35px; height: 35px;" />
                    </ng-container>
                    <!-- Header for name -->
                    <ng-container *ngSwitchCase="'TEXT'">
                      <!-- if TEXT === link -->
                      <ng-container *ngIf="column.routerLink || column.link; else newDeb">
                        <ng-container *ngIf="column.routerLink">
                          <p style="cursor: pointer" (click)="onClick(column.routerLink)">
                            {{ !column.translatedValue ? column.value : column.value | translate | truncateWords : 35 }}
                          </p>

                        </ng-container>
                        <ng-container *ngIf="column.link">
                          <a [href]="column.link" target="_blank" rel="noopener noreferrer">
                            {{ !column.translatedValue ? column.value : column.value | translate| truncateWords : 35 }}
                          </a>
                        </ng-container>
                      </ng-container>
                      <ng-template #newDeb>
                        <p [ngStyle]="{'direction':column.direction}">
                          {{ !column.translatedValue ? column.value : column.value | translate | truncateWords : 35 }}
                        </p>

                      </ng-template>
                    </ng-container>

                    <!-- Header for Currency -->
                    <ng-container *ngSwitchCase="'CURRENCY'">
                      <span [innerHTML]="Number(column.value) | customCurrency"></span>
                    </ng-container>

                    <!-- Header for Date -->
                    <ng-container *ngSwitchCase="'DATE'">
                      {{ column.value | date }}
                    </ng-container>

                    <!-- Header for Status -->
                    <ng-container *ngSwitchCase="'STATUS'">
                      <div class="status-box {{ column.class}}">
                        {{ !column.translatedValue ? column.value : column.value | translate }}
                      </div>
                    </ng-container>

                    <!-- Header for Actions types -->
                    <!-- Delete -->
                    <ng-container *ngSwitchCase="'ACTION'">
                      <div ngbDropdown class="d-inline-block">
                        <button class="btn p-0 text-primary dropdown-noArrow" id="ticketActions" ngbDropdownToggle>
                          <i class="fas fa-ellipsis-h"></i>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="ticketActions">
                          <ng-container *ngFor="let action of column.actions">
                            <button *ngIf="action.show" ngbDropdownItem
                              (click)="onButtonValueChange(action.translatedValue, row.id)" (click)="action.onClick()">
                              <i class="{{ action.faStyle }}"></i>
                              <span class="mr-2 ml-2">{{ "change-status-to" | translate }} {{ action.translatedValue |
                                translate
                                }}</span>
                            </button>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                    <!-- VIEW -->
                    <ng-container *ngSwitchCase="'VIEW'">
                      <a [routerLink]="[column.routerLink]"><i class="far fa-eye"></i> {{ "View" |
                        translate }}
                      </a>
                    </ng-container>
                  </ng-container>

                </th>
              </tr>
            </div>

          </tbody>
        </div>
      </div>
    </div>

  </div>

</body>









<!--
<div class="collapsedCard mt-4 ">
  <div class="collapse-header d-flex flex-wrap justify-content-between " id="a2" (click)="accordion('a2')">
    <div class="">
      <h6> أهم نص إفتراضي</h6>
    </div>
    <div class=""> <i [@rotatedState]="openedAccordions['a2']?'default':'rotated'" class="fas fa-chevron-up"></i></div>
  </div>
  <div [@smoothCollapse]="openedAccordions['a2'] ? 'initial':'finale'" class="table-responsive  collapse-body">
    <div class="table m-1 p-1">
      <tbody class="table-body">
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            عنوان:
          </th>
          <th>
            نص إفتراضي
          </th>
        </tr>
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            عنوان:
          </th>
          <th>
            نص إفتراضي
          </th>
        </tr>
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            عنوان:
          </th>
          <th>
            نص إفتراضي
          </th>
        </tr>
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            عنوان:
          </th>
          <th>
            نص إفتراضي
          </th>
        </tr>
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            الإجراءات:
          </th>
          <th>
            <i class="fas fa-edit m-2"></i>
            <i class="fas fa-trash-alt m-2"></i>
          </th>
        </tr>
      </tbody>
    </div>
  </div>
</div>


<div class="collapsedCard mt-4 ">
  <div class="collapse-header d-flex flex-wrap justify-content-between " id="a3" (click)="accordion('a3')">
    <div class="">
      <h6> أهم نص إفتراضي</h6>
    </div>
    <div class=""> <i [@rotatedState]="openedAccordions['a3']?'default':'rotated'" class="fas fa-chevron-up"></i></div>
  </div>
  <div [@smoothCollapse]="openedAccordions['a3'] ? 'initial':'finale'" class="table-responsive  collapse-body">
    <div class="table m-1 p-1">
      <tbody class="table-body">
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            عنوان:
          </th>
          <th>
            نص إفتراضي
          </th>
        </tr>
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            عنوان:
          </th>
          <th>
            نص إفتراضي
          </th>
        </tr>
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            عنوان:
          </th>
          <th>
            نص إفتراضي
          </th>
        </tr>
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            عنوان:
          </th>
          <th>
            نص إفتراضي
          </th>
        </tr>
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            الإجراءات:
          </th>
          <th>
            <i class="fas fa-edit m-2"></i>
            <i class="fas fa-trash-alt m-2"></i>
          </th>
        </tr>
      </tbody>
    </div>
  </div>
</div>

<div class="collapsedCard mt-4 ">
  <div class="collapse-header d-flex flex-wrap justify-content-between " id="a4" (click)="accordion('a4')">
    <div class="">
      <h6> أهم نص إفتراضي</h6>
    </div>
    <div class=""> <i [@rotatedState]="openedAccordions['a4']?'default':'rotated'" class="fas fa-chevron-up"></i></div>
  </div>
  <div [@smoothCollapse]="openedAccordions['a4'] ? 'initial':'finale'" class="table-responsive  collapse-body">
    <div class="table m-1 p-1">
      <tbody class="table-body">
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            عنوان:
          </th>
          <th>
            نص إفتراضي
          </th>
        </tr>
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            عنوان:
          </th>
          <th>
            نص إفتراضي
          </th>
        </tr>
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            عنوان:
          </th>
          <th>
            نص إفتراضي
          </th>
        </tr>
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            عنوان:
          </th>
          <th>
            نص إفتراضي
          </th>
        </tr>
        <tr class="d-flex flex-wrap justify-content-between">
          <th>
            الإجراءات:
          </th>
          <th>
            <i class="fas fa-edit m-2"></i>
            <i class="fas fa-trash-alt m-2"></i>
          </th>
        </tr>
      </tbody>
    </div>
  </div>
</div> -->