import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CropperModalComponent } from '../cropper-modal/cropper-modal.component';

@Component({
  selector: 'app-evento-crop-uploader',
  templateUrl: './evento-crop-uploader.component.html',
})
export class EventoCropUploaderComponent implements OnInit {
  @Input() label: string;
  @Input() ratio: string;
  @Input() imgSrc: string;
  @Input() acceptedFiles: string;
  @Output() croppedImageSrc = new EventEmitter<string>(null);
  files: File[] = [];
  croppedImg: string;
  fileEvent: any;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  fileChangeEvent(event: any): void {
    if (!event.target.files.length) {
      return;
    }
    this.fileEvent = event;
    let aspectRatio;
    const modalRef = this.modalService.open(CropperModalComponent);
    modalRef.componentInstance.imageChangedEvent = this.fileEvent;

    if (this.ratio === 'landscape') {
      aspectRatio = 2 / 1;
    }
    if (this.ratio === 'square') {
      aspectRatio = 1 / 1;
    }
    modalRef.componentInstance.aspectRatio = aspectRatio;

    modalRef.dismissed.subscribe((res) => {
      if (res) {
        this.croppedImg = res;
        this.croppedImageSrc.emit(res);
      }
    });
  }

  changeCrop(): void {
    this.fileChangeEvent(this.fileEvent);
  }
}
