<label *ngIf="label">
  {{ label }}
</label>
<div class="uploadWrapper uploadWrapper--files">
  <input
    type="file"
    id="file-input"
    [accept]="acceptedFiles"
    [multiple]="multiple"
    (change)="fileChangeEvent($event)"
  />
  <label class="uploadLabel mb-0" for="file-input">
    <div class="d-flex align-items-center">
      <i class="fas fa-cloud-upload-alt text-primary"></i>
      <p class="mb-0 ml-3" *ngIf="!this.files.length">
        {{ "Upload the certificate file" | translate }}
        <!-- <span class="text-dark d-block">{{ "optional" | translate }}</span> -->
      </p>
      <p class="mb-0 ml-3" *ngIf="this.files.length">
        {{ this.files[0]?.name }}
      </p>
    </div>
  </label>
</div>
