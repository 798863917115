<div class="row justify-content-center text-center mb-3 pt-5 no-gutters">
  <div class="col-12 col-md-9">
    <h2 *ngIf="title" class="title">
      {{title}}
    </h2>
    <p *ngIf="desc" class="desc">
      {{desc}}
    </p>
  </div>
</div>
