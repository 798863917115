<div
  class="eventCard"
  [routerLink]="['/event-details/', event.id]"
  *ngIf="event; else loadingSkeleton"
>
  <div class="cover ratio-wrapper">
    <img
      [src]="event.image?.image?.url || 'assets/images/placeholder-img.jpg'"
      class="ratio-wrapper__el"
      alt=""
    />
  </div>

  <div class="body">
    <h4>
      {{
        helpers.getElementTranslatedName(event)
          ? helpers.getElementTranslatedName(event)
          : event.nameAr
      }}
    </h4>
    <p class="date">
      {{
        event.schedules[0]?.startAt * 1000
          | localizedDate : "EEEE, MMM d, y, h:mm:ss a" : translate.currentLang
      }}
    </p>
    <div>
      <span
        class="category"
        [ngStyle]="{
          'background-color': helpers.getCategoryColor(event.category)
        }"
        >{{ helpers.getElementTranslatedName(event.category) }}</span
      >
    </div>
  </div>
</div>

<ng-template #loadingSkeleton>
  <div class="eventCard skeleton">
    <div class="eventCard-cover ratio-wrapper">
      <div class="skeleton-box skeleton-box__image ratio-wrapper__el"></div>
    </div>

    <div class="eventCard-body">
      <p class="eventCard-cardTitle">
        <span class="skeleton-box"></span>
      </p>
      <span class="eventCard-cardDate">
        <span class="skeleton-box" style="max-width: 50%"></span>
      </span>
      <p class="eventCard-cardText">
        <span class="skeleton-box"></span>
        <span class="skeleton-box" style="max-width: 80%"></span>
        <span class="skeleton-box" style="max-width: 60%"></span>
      </p>
    </div>
  </div>
</ng-template>
