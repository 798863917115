import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nodata',
  templateUrl: './nodata.component.html',
  styleUrls: ['./nodata.component.scss'],
})
export class NodataComponent implements OnInit {
  @Input() title: string;
  @Input() desc = 'لم يتم بيع اى من التذاكر بعد';
  @Input() img: string;
  @Input() link: { label: string; path: string } = {
    label: 'إذهب إلي',
    path: '/404',
  };
  constructor() {}

  ngOnInit(): void {}
}
