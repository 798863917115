<div class="wizard2 wizard2-vertical">
  <h2 class="wizard2-title">{{ "New event" | translate }}</h2>
  <p class="wizard2-desc">
    {{
      "You are minutes away from adding your event. Please fill out the steps below"
        | translate
    }}
  </p>
  <div class="wizard2-tabs">
    <ul class="wizard2-items">
      <!-- steps titles  -->
      <li
        *ngFor="let item of steps$ | async; let idx = index"
        class="wizard2-item"
        [routerLink]="item.route"
        [queryParams]="{ eventId: createdEventId$ | async }"
        routerLinkActive="active"
        [class.disabled]="
          (createdEventId$ | async)
            ? idx === 0 || idx === 1
              ? true
              : false
            : !newEvent?.activatedSteps[idx + 1]
        "
        (click)="goToStep(idx + 1)"
      >
        <div class="wizard2-step">
          <!-- <i class="wizard2--check fas fa-check"></i> -->
          <p class="wizard2--icon">{{ idx + 1 }}</p>
        </div>
        <div class="wizard2-info">
          <!-- <span class="wizard2-label"> {{item.step}}</span> -->
          <span class="wizard2-name"> {{ item.name | translate }}</span>
        </div>
      </li>
    </ul>
  </div>
</div>
